import styled, { css } from 'styled-components';

const generateWidthCss = (columnsSpanned, numberColumns) => {
  const width = `calc(100 * (${columnsSpanned}% / ${numberColumns}))`;
  return css`
    flex-basis: ${width};
    max-width: ${width};
  `;
};

const offsetCss = css`
  margin-left: ${props =>
    props.lgOffset && `calc(100 * ${props.lgOffset}% / ${props.theme.numberColumns})`};

  @media ${props => props.theme.breakpoints.md} {
    margin-left: ${props =>
      props.mdOffset && `calc(100 * ${props.mdOffset}% / ${props.theme.numberColumns})`};
  }
  @media ${props => props.theme.breakpoints.sm} {
    margin-left: ${props =>
      props.smOffset && `calc(100 * ${props.smOffset}% / ${props.theme.numberColumns})`};
  }
  @media ${props => props.theme.breakpoints.xs} {
    margin-left: ${props =>
      props.xsOffset && `calc(100 * ${props.xsOffset}% / ${props.theme.numberColumns})`};
  }
`;

const enums = {
  sizes: {
    xs: {
      min: '0px',
      gutterWidth: '16px',
    },
    sm: {
      min: '567px',
      gutterWidth: '24px',
    },
    md: {
      min: '768px',
      gutterWidth: '48px',
    },
    lg: {
      min: '880px',
      gutterWidth: '48px',
    },
  },
  columnGap: '8px',
  justifyOptions: {
    // note that not including all justify-content options, just the ones I suspect are useful
    center: 'center',
    spaceBetween: 'space-between',
    spaceAround: 'space-around',
    spaceEvenly: 'space-evenly',
  },
  alignOptions: {
    // only including center for now, since can't see a reason to use the other options
    center: 'center',
  },
};

export const Grid = styled.div`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  @media ${props => props.theme.breakpoints.lg} {
    padding-right: ${enums.sizes.lg.gutterWidth};
    padding-left: ${enums.sizes.lg.gutterWidth};
  }
  @media ${props => props.theme.breakpoints.md} {
    padding-right: ${enums.sizes.md.gutterWidth};
    padding-left: ${enums.sizes.md.gutterWidth};
  }
  @media ${props => props.theme.breakpoints.sm} {
    padding-right: ${enums.sizes.sm.gutterWidth};
    padding-left: ${enums.sizes.sm.gutterWidth};
  }
  @media ${props => props.theme.breakpoints.xs} {
    padding-right: ${enums.sizes.xs.gutterWidth};
    padding-left: ${enums.sizes.xs.gutterWidth};
  }
`;

// TODO potentially make Row props to control flexbox spacing
export const Row = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 calc((${enums.columnGap} * 0.5) * -1);
  margin-top: ${props => props.marginTop && props.theme.spacing(props.marginTop)};
  margin-bottom: ${props => props.marginBottom && props.theme.spacing(props.marginBottom)};
  justify-content: ${props => props.justify && enums.justifyOptions[props.justify]};
`;

export const Col = styled.div`
  box-sizing: border-box;

  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;

  padding: 0 calc(${enums.columnGap} / 2);

  ${props => generateWidthCss(props.lg, props.theme.numberColumns)}

  @media ${props => props.theme.breakpoints.md} {
    ${props => generateWidthCss(props.md, props.theme.numberColumns)}
  }
  @media ${props => props.theme.breakpoints.sm} {
    ${props => generateWidthCss(props.sm, props.theme.numberColumns)}
  }
  @media ${props => props.theme.breakpoints.xs} {
    ${props => generateWidthCss(props.xs, props.theme.numberColumns)}
  }

  ${offsetCss}

  display: flex;
  flex-direction: column;
  align-items: ${props => props.align && enums.alignOptions[props.align]};
`;
