// @flow

import uts46 from 'idna-uts46-hx';

/**
 * Convert any string to a valid handshake domain name.
 *
 * @param {string} domainName
 * @return {string}
 */
const maximumDomainNameLength = 63;
function normalizeDomainName(domainName) {
  let normalizedDomainName = domainName.toLowerCase();
  normalizedDomainName = normalizedDomainName.substring(0, maximumDomainNameLength);
  normalizedDomainName = normalizedDomainName.replace(/[^a-z0-9-_]+/g, '');
  normalizedDomainName = normalizedDomainName.replace(/^[-_]+/g, '');
  normalizedDomainName = normalizedDomainName.replace(/[-_]+$/g, '');
  return normalizedDomainName;
}

/**
 * parseSearchQuery
 *
 * Use me when you want to search for a domain name
 */
export const parseSearchQuery = (rawDomainName) => {
  try {
    return normalizeDomainName(uts46.toAscii(rawDomainName, { useStd3ASCII: true }));
  } catch (e) {
    return normalizeDomainName(rawDomainName);
  }
};
