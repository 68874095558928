import React from 'react';
import moment from 'moment';
import punycode from 'punycode';

const SI_SYMBOLS = ["", "K", "M", "G", "T", "P", "E"];

const abbreviatedLocaleString = (number) => {
    if (number === 0 || number <= 999) return `${number}`;
    const siSymbol = Math.floor(Math.log10(Math.abs(number)) / 3);
    const suffix = SI_SYMBOLS[siSymbol];
    const scale = 10 ** (siSymbol * 3);
    const scaledNumber = number / scale;
    return scaledNumber.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }) + suffix;
};

const abbreviatedLocaleStringWithDigits = (number) => {
    if (number === 0 || number <= 999) return `${number}`;
    const siSymbol = Math.floor(Math.log10(Math.abs(number)) / 3);
    const suffix = SI_SYMBOLS[siSymbol];
    const scale = 10 ** (siSymbol * 3);
    const scaledNumber = number / scale;
    return scaledNumber.toLocaleString(undefined, {
        minimumFractionDigits: suffix === 'K' ? 0 : 1,
        maximumFractionDigits: suffix === 'K' ? 0 : 1,
    }) + suffix;
};

const domainNameWithPunycode = (subdomain, pretty = false) => {
  const parsedParts = [];
  let hasPunycode = false;

  subdomain.split('.').forEach(part => {
    try {
      const asPunycode = punycode.toUnicode(part);
      const isPunycode = part !== asPunycode;
      if (isPunycode) {
        hasPunycode = true;
        parsedParts.push(asPunycode);
      } else {
        parsedParts.push(part);
      }
    } catch (e) {
      parsedParts.push(part);
    }
  });

  if (hasPunycode && pretty) return `${parsedParts.join('.')}/`;
  if (hasPunycode && !pretty) return `${subdomain}/ (${parsedParts.join('.')})`;
  return `${subdomain}/`;
};

const domainNameWithoutPunycode = (subdomain) => {
  return domainNameWithPunycode(subdomain, true);
}

const localeStringDefault = (originalValue) => {
  return parseFloat(originalValue).toLocaleString(undefined, { minimumFractionDigits: 0 })
}

const dateMmmYy = (originalValue, fullYear = false) => {
  if (fullYear) return moment.utc(originalValue).format("MMM YYYY");
  return moment.utc(originalValue).format("MMM 'YY");
}

const dateMmmDdYyyy = (originalValue, fullYear = false) => {
  if (fullYear) return moment.utc(originalValue).format("MMM DD, YYYY");
  return moment.utc(originalValue).format("MMM DD, 'YY");
}

const stringWithDollarAmounts = (string) => {
  return string.split(' ').map((token, i) => {
    const tokenKey = `${i}${token}`;

    if (token.startsWith('$')) return <span key={tokenKey}>{`${token} `}</span>;
    return <React.Fragment key={tokenKey}>{`${token} `}</React.Fragment>;
  });
}

export {
  dateMmmYy,
  dateMmmDdYyyy,
  localeStringDefault,
  domainNameWithPunycode,
  abbreviatedLocaleString,
  stringWithDollarAmounts,
  domainNameWithoutPunycode,
  abbreviatedLocaleStringWithDigits,
};