import { graphql } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';

import { Button } from '../components/Button';
import { theme } from '../theme';
import { navSections } from '../components/navbar';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Grid, Row, Col } from '../components/grid';
import { Small, Medium, Huge, HeaderSmall, DisplaySmall } from '../components/text';
import { parseSearchQuery } from '../utils/parseSearchQuery';
import { usePageMetaAttributes } from '../utils/usePageMetaAttributes';
import { stringWithDollarAmounts } from '../components/StatsPage/formatters';

export const RegistryPageQuery = graphql`
  query RegistryPageQuery {
    copy: contentfulRegistryLandingPage {
      caseStudyLinkHref
      caseStudyTitle
      caseStudyTotallyLegitQuote
      ctaTitle
      ctaUnderlineMessage
      ctaUnderlineMessageHref
      learnMoreLinkHref
      pageSubtitle
      pageTitle
    }
  }
`;

const GRID_AREAS = Object.freeze({
  CTA: 'cta',
  HEADING: 'heading',
  CASE_STUDY: 'caseStudy',
});

const stackCss = numUnits => css`
  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(numUnits)};
  }
`;

const RegistryPage = ({ data: { copy } }) => {
  const meta = usePageMetaAttributes('registry');
  const [tldInputValue, setTldInputValue] = React.useState('');

  const onSubmit = e => {
    e.preventDefault();
    window.location = `/registry/list/${parseSearchQuery(tldInputValue)}`;
  };

  return (
    <Layout currentSection={navSections.PRODUCTS}>
      <SEO {...meta} />
      <RegistryPageContentLayout>
        <GridArea gridArea={GRID_AREAS.HEADING} css={stackCss(1.25)}>
          <DisplaySmall weight="bold">{copy.pageTitle}</DisplaySmall>
          <Medium>{copy.pageSubtitle}</Medium>
          <Link rel="noopener noreferrer" href={copy.learnMoreLinkHref} target="_blank">
            Learn more about the registry
          </Link>
        </GridArea>
        <GridArea
          gridArea={GRID_AREAS.CASE_STUDY}
          css={css`
            border-top: 2px solid ${({ theme }) => theme.color.proBrand};
            @media ${({ theme }) => theme.breakpoints.sm} {
              margin-top: ${({ theme }) => theme.spacing(2)};
            }
          `}>
          <div
            css={css`
              margin-top: ${({ theme }) => theme.spacing(3)};
              ${stackCss(2)}
            `}>
            <HeaderSmall
              weight="bold"
              colored="grey60"
              css={css`
                span {
                  color: ${({ theme }) => theme.color.greenLight};
                }
              `}>
              {stringWithDollarAmounts(copy.caseStudyTitle)}
            </HeaderSmall>
            <Small weight="medium" colored="grey70">
              {copy.caseStudyTotallyLegitQuote}
            </Small>
            <Link rel="noopener noreferrer" href={copy.caseStudyLinkHref} target="_blank">
              View case study
            </Link>
          </div>
        </GridArea>
        <GridArea gridArea={GRID_AREAS.CTA} css={stackCss(2)}>
          <CtaCard>
            <form onSubmit={onSubmit}>
              <Huge weight="bold" css={{ marginBottom: theme.spacing(4) }}>
                {copy.ctaTitle}
              </Huge>
              <Small weight="medium" htmlFor="tld-input" css={{ marginBottom: theme.spacing(1) }}>
                Your handshake name
              </Small>
              <div css={{ marginBottom: theme.spacing(2) }}>
                <Input
                  id="tld-input"
                  value={tldInputValue}
                  autoFocus
                  onChange={e => setTldInputValue(e.target.value)}
                  placeholder="Your top-level domain"
                />
              </div>
              <Button
                size="large"
                disabled={!tldInputValue}
                css={{ width: '100%' }}>
                Next
              </Button>
            </form>
          </CtaCard>
          <CtaUnderlineMessage>
            {copy.ctaUnderlineMessage}
            <a rel="noopener noreferrer" target="_blank" href={copy.ctaUnderlineMessageHref}>
              {` Learn More.`}
            </a>
          </CtaUnderlineMessage>
        </GridArea>
      </RegistryPageContentLayout>
    </Layout>
  );
};

const rowCss = css`
  margin-left: 0;
  margin-right: 0;
`;

const gridLayoutCss = css`
  width: 100%;
  display: grid;
  row-gap: ${({ theme }) => theme.spacing(6)};
  max-width: 784px;
  column-gap: ${({ theme }) => theme.spacing(9.25)};
  grid-template-areas:
    '${GRID_AREAS.HEADING}    ${GRID_AREAS.CTA}'
    '${GRID_AREAS.CASE_STUDY} ${GRID_AREAS.CTA}';
  grid-template-columns: 5fr 6fr;

  @media ${({ theme }) => theme.breakpoints.sm} {
    row-gap: ${({ theme }) => theme.spacing(5)};
    column-gap: 0;
    grid-template-areas:
      '${GRID_AREAS.HEADING}'
      '${GRID_AREAS.CTA}'
      '${GRID_AREAS.CASE_STUDY}';
    grid-template-columns: 1fr;
  }
`;

const RegistryPageContentLayout = ({ children }) => (
  <Grid>
    <Row justify="center" marginTop={10} css={rowCss}>
      <Col lg={12} md={12} sm={12} xs={12} css={{ padding: 0 }}>
        <Row justify="center" css={rowCss} marginBottom={16}>
          <div css={gridLayoutCss}>{children}</div>
        </Row>
      </Col>
    </Row>
  </Grid>
);

const CtaCard = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(5)};
  background: ${({ theme }) => theme.gradients.greenGreen};
  box-shadow: 0px 1px 36px 1px rgba(0, 0, 0, 0.13);
  border-radius: ${({ theme }) => theme.spacing(2.25)};
`;

const GridArea = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
`;

const Input = styled.input`
  width: 100%;
  height: ${({ theme }) => theme.spacing(6)};
  border: none;
  outline: none;
  font-size: ${({ theme }) => theme.text.small.size};
  line-height: ${({ theme }) => theme.text.small.lineHeight};
  padding-left: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(0.5)};

  ::placeholder {
    color: ${({ theme }) => theme.color.grey50};
    opacity: 1;
  }
`;

const Link = styled(Small).attrs(props => ({ as: 'a', weight: 'bold', colored: 'nbPrimary' }))`
  width: max-content;
  display: block;
  transition: ${({ theme }) => theme.transitions.color};
  &:hover {
    color: ${({ theme }) => theme.color.nbPrimaryDark};
  }
`;

const CtaUnderlineMessage = styled(Small).attrs(props => ({
  align: 'center',
  weight: 'medium',
  colored: 'grey70',
}))`
  a,
  a:visited {
    color: ${({ theme }) => theme.color.nbPrimary} !important;
  }

  a:hover {
    color: ${({ theme }) => theme.color.nbPrimaryDark};
  }
`;

export default RegistryPage;
